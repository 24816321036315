import { ActionIcon, Anchor, Badge, Group } from "@mantine/core";
import { IconShoppingCart } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import useProjectCartItems from "../hooks/useCartItems";

const CartNavigation = () => {
  const navigate = useNavigate();
  const { cartItems } = useProjectCartItems();
  
  if (cartItems.length === 0) {
    return null;
  }

  return (
    <Anchor
      onClick={(e) => {
        e.preventDefault();
        navigate("/get-started");
      }}
    >
      <Group gap={3}>
        <ActionIcon variant="subtle" size={34} pos="relative">
          <IconShoppingCart strokeWidth={1.5} />
          <Badge 
            size="xs" 
            variant="filled" 
            pos="absolute" 
            top={-4} 
            right={-4}
            radius="xl"
            color="green.9"
          >
            {cartItems.length}
          </Badge>
        </ActionIcon>
      </Group>
    </Anchor>
  );
};

export default CartNavigation;
