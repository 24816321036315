import { Button, Group, List, Space, Text, Title } from "@mantine/core";
import { IconArrowRight, IconPlus } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Conditional from "../../components/Conditional";
import { FadeTransition, SlideTransition } from "../../components/Transitions";
import useProjectCartItems from "../../hooks/useCartItems";
import PlantEntryItem from "./PlantEntryItem";

interface Props {
  onNext: () => void;
  transitionDirection: "right" | "left";
}

const PlantEntry: React.FC<Props> = ({ onNext, transitionDirection }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { cartItems, removeCartItem } = useProjectCartItems();

  return (
    <SlideTransition direction={transitionDirection}>
      <Container>
        <Title>{t("requestPlantEntryText1")}</Title>
        <Text>{t("requestPlantEntryText2")}</Text>
        <Space h={"lg"} />
        
        <Conditional renderIf={cartItems.length === 0}>
          <Group justify="center" mt="xl">
            <Button
              onClick={() => navigate("/")}
              leftSection={<IconPlus />}
              color="green"
              size="lg"
            >
              {t("requestPlantEntryText12")}
            </Button>
          </Group>
        </Conditional>

        <Conditional renderIf={cartItems.length > 0}>
          <List>
            {cartItems.map((item, index) => (
              <PlantEntryItem key={index} item={item} removeCartItem={removeCartItem} />
            ))}
          </List>
        </Conditional>
        
        <Conditional renderIf={cartItems.length > 0}>
          <FadeTransition>
            <Group justify="center" mt="md">
              <Button
                onClick={() => navigate("/")}
                leftSection={<IconPlus />}
                color="green"
              >
                {t("requestPlantEntryText11")}
              </Button>
              <Button
                data-testid="finish-plant-entry-btn"
                onClick={onNext}
                rightSection={<IconArrowRight />}
                variant="outline"
                color="green"
              >
                {t("requestPlantEntryText8")}
              </Button>
            </Group>
          </FadeTransition>
        </Conditional>
      </Container>
    </SlideTransition>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 928px;

  @media (max-width: 576px) {
    border-top: 1px solid #eee;
  }
`;

PlantEntry.defaultProps = {
  transitionDirection: "right",
};

export default PlantEntry;
