const publicRequest = {
  requestsText1: "Plant entry",
  requestsText2: "Choose your plants",
  requestsText3: "Project info",
  requestsText4: "Tell us about your project",
  requestsText5: "Get estimate",
  requestsText6: "See plant pricing, availability, and photos",

  requestPlantEntryItemText1: "Remove",

  requestPlantEntryText1: "Plant Entry",
  requestPlantEntryText2: "Choose your plants and size specifications",
  requestPlantEntryText3: "Add plant",
  requestPlantEntryText4: "Plant:",
  requestPlantEntryText5: "Size:",
  requestPlantEntryText6: "Quantity:",
  requestPlantEntryText8: "Save & Continue",
  requestPlantEntryText9: "Back to Categories",
  requestPlantEntryText10: "Add more items",
  requestPlantEntryText11: "Add more plants",
  requestPlantEntryText12: "Add plants",

  requestRequestSuccessText1: "Your request has been sent successfully!",
  requestRequestSuccessText2: "We will send you a estimate within 24-hours to your email with plant pricing, availability, and photos.",
  requestRequestSuccessText3: "Dashboard",
  requestRequestSuccessText4: "Login",
  requestRequestSuccessText5: "Continue Shopping",

  requestProjectInfoText1: "Project Information",
  requestProjectInfoText2: "Provide information about your project so that we can optimize for delivery.",
  requestProjectInfoText3: "Project name",
  requestProjectInfoText4: "Name to refer to this project",
  requestProjectInfoText5: "A project name is required",
  requestProjectInfoText6: "Delivery phasing",
  requestProjectInfoText7: "Deliveries will be phased out of the project",
  requestProjectInfoText8: "Delivery date",
  requestProjectInfoText9: "Invalid delivery date",
  requestProjectInfoText10: "Delivery address",
  requestProjectInfoText11: "The address for delivery (including city, state, and zip code)",
  requestProjectInfoText12: "A valid address is required",
  requestProjectInfoText13: "Previous",
  requestProjectInfoText14: "Finish",
  requestProjectInfoText15: "Email to receive estimate",
  requestProjectInfoText16: "The email address where we will send your estimate",
  requestProjectInfoText17: "A valid email is required",

  requestSelectPlantItemText1: "Select",

  useSubmitRfqText1: "Success",
  useSubmitRfqText2: "Your request has been submitted successfully",
  useSubmitRfqText3: "Error",
  useSubmitRfqText4: "Couldn't submit request. Email william@bidscape.com for help.",
};

export default publicRequest;
